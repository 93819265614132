@use "variables";

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;
    background-color: variables.$secondaryColor;
}

button.btn-primary {
    background-color: variables.$primaryColor;
    border-color: variables.$primaryColor;
}

.navbar {
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
}

.loading-spinner--container {
    display: flex;
    align-items: baseline;
    justify-content: center;
    & > span {
        margin-left: 0.5rem;
    }
}