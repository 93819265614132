/**
  * Global variables
 */

$primaryColor: #004188;
$secondaryColor: #f1f3f6;

$chartColors: [
  #FF6384FF,
  #36A2EBFF,
  #FFCE56FF,
  #4BC0C0FF,
  #9966FFFF,
  #FF9F40FF
];

:export {
  chartColors: $chartColors
}