@import "../../variables.scss";

footer {
  a {
    color: $primaryColor;
    font-weight: bold;
    text-decoration: none;
  }

  .legal {
    font-weight: normal;
    text-decoration: underline;
    margin: 0 1em;
    color: #707070;
  }
}
