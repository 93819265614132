.fact {
  display: flex;
  background-color: white;
  padding: 1em;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  border-radius: 0.25em;
  align-items: center;
  justify-content: space-between;
}

.fact-text {
  display: flex;
  flex-direction: column;
}

.fact-text-title {
  text-transform: uppercase;
  color: #707070;
}

.fact-text-value {
  font-size: 1.5em;
  font-weight: bold;
}

.fact-icon {
  background-color: #f1f3f6;
  border-radius: 100%;
  padding: 0.25em;
}
