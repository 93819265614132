.chart-filter-container {
  display: flex;
  justify-content: space-between;
}

.custom-chart--wrapper {
  //height: .75vh;
  //max-height: 1000px;
  .custom-chart {
    padding-top: 1rem;

  }
}
